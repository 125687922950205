import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import axios from "axios";
import parse from "html-react-parser";
import { Spin, Modal } from "antd";
import Slider from "react-slick";
import UnderCons from "./components/UnderCons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ads_content: "",
      img1_url: "",
      img2_url: "",
      video_url: "",
      companies: [],
      category_list: [],
      categoryLoader: false,
      companyLoader: false,
      viewModalStatus: false,
      sliderLoader: false,
      slider_list: [],
      industry_loader: false,
      industry_list: [],
    };
  }

  componentDidMount() {
    this.get_header_data();
    this.get_company_list();
    this.get_category_list();
    this.get_slider_images();
    this.get_industry_list();
    this.setState({
      viewModalStatus: true,
    });
  }
  get_industry_list = () => {
    axios
      .get(URLs.get_home_page_industry_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            industry_list: response.data.data,
            industry_loader: false,
          });
        } else {
          this.setState({ industry_loader: false });
        }
      })
      .catch((error) => {
        this.setState({ industry_loader: false });
        // console.error("Warning:", error);
      });
  };
  get_company_list = () => {
    this.setState({ companyLoader: true });
    axios
      .get(URLs.get_company_list)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          for (let item of response.data.data) {
            let image_url = BASE_URL + "/" + item.logo_file_name;
            item.image_url = image_url;
            arr.push(item);
          }
          this.setState({
            companies: arr,
            companyLoader: false,
          });
        } else {
          this.setState({ companyLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ companyLoader: false });
        // console.error("Warning:", error);
      });
  };
  get_category_list = () => {
    this.setState({ categoryLoader: true });
    axios
      .get(URLs.get_category_list)
      .then((response) => {
        console.log("cate response", response);
        if (response.data.status == "1") {
          this.setState({
            category_list: response.data.data,
            categoryLoader: false,
          });
        } else {
          this.setState({ loading: false, categoryLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, categoryLoader: false });
        // console.error("Warning:", error);
      });
  };
  get_header_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_data)
      .then((response) => {
        if (response.data.status == "1") {
          let img1_url = BASE_URL + "/" + response.data.data[0].img1;
          let img2_url = BASE_URL + "/" + response.data.data[0].img2;
          let video_file_name =
            BASE_URL + "/" + response.data.data[0].video_file_name;
          this.setState({
            ads_content: response.data.data[0].ads_content,
            img1_url: img1_url,
            img2_url: img2_url,
            img1_link: response.data.data[0].img1_link,
            img2_link: response.data.data[0].img2_link,
            video_url: video_file_name,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  get_slider_images = () => {
    this.setState({ sliderLoader: true });
    axios
      .get(URLs.get_slider_image_list)
      .then((response) => {
        console.log("cate response", response);
        if (response.data.status == "1") {
          this.setState({
            slider_list: response.data.data,
            sliderLoader: false,
          });
        } else {
          this.setState({ sliderLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ sliderLoader: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
    };
    return (
      <div className="wrapper ovh home-page">
        <div className="advertisment-div">
          <div className="row">
            <div className="col-lg-3">
              {this.state.video_url && (
                <video width="100%" height="200" controls>
                  <source src={this.state.video_url} />
                </video>
              )}
            </div>
            <div className="col-lg-3">
              <a href={this.state.img1_link} target="_blank">
                <img
                  style={{ width: "100%", borderRadius: 3 }}
                  src={this.state.img1_url}
                />
              </a>
            </div>
            <div className="col-lg-3">
              <a href={this.state.img2_link} target="_blank">
                <img
                  style={{ width: "100%", borderRadius: 3 }}
                  src={this.state.img2_url}
                />{" "}
              </a>
            </div>
            <div className="col-lg-3">
              <div className="noticeboard">{parse(this.state.ads_content)}</div>
            </div>
          </div>
        </div>
        <UnderCons />
        <Header />
        <div className="body_content">
          {/* <!-- Home Banner Style V1 --> */}
          <div className="homepage-slider">
            <Spin spinning={this.state.sliderLoader}>
              <Slider {...settings}>
                {[...this.state.slider_list]
                  .sort((a, b) => a.id - b.id) // Sort by id in ascending order
                  .map((item, index) => {
                    let url = BASE_URL + "/" + item.image;
                    return (
                      <div className="single-slide" key={index}>
                        <h3>
                          <img src={url} alt={`Slide ${item.id}`} />
                        </h3>
                      </div>
                    );
                  })}

              </Slider>
            </Spin>
          </div>
          <section className="hero-home10 bdrs24 p-0 overflow-hidden">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main-banner-wrapper home10-hero-content">
                    <div className="navi_pagi_bottom_center dots_nav_light banner-style-one slider-1-grid owl-theme owl-carousel">
                      <div
                        className="slide slide-one"
                        style={{
                          backgroundImage:
                            "url('./assets/images/home/home-3.jpg')",
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-xl-7 mx-auto text-center">
                              <h3 className="banner-title">
                                With talented freelancers{" "}
                                <br className="d-none d-lg-block" />
                                ando more work.
                              </h3>
                              <p className="banner-text text-white ff-heading mb30">
                                Millions of people use freeio.com to turn their
                                ideas into reality.
                              </p>
                              <div className="d-sm-flex justify-content-center banner-btn">
                                <a
                                  href="page-project-v1.html"
                                  className="ud-btn btn-white me-0 me-sm-3"
                                >
                                  Find Work
                                </a>
                                <a
                                  href="page-freelancer-v1.html"
                                  className="ud-btn btn-dark"
                                >
                                  Find Talent
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slide slide-one"
                        style={{
                          backgroundImage:
                            "url('./assets/images/home/home-2.jpg')",
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-xl-7 mx-auto text-center">
                              <h3 className="banner-title">
                                Freelance Services For{" "}
                                <br className="d-none d-lg-block" />
                                Your Business
                              </h3>
                              <p className="banner-text text-white ff-heading mb30">
                                Millions of people use freeio.com to turn their
                                ideas into reality.
                              </p>
                              <div className="d-sm-flex justify-content-center banner-btn">
                                <a
                                  href="page-project-v1.html"
                                  className="ud-btn btn-white me-0 me-sm-3"
                                >
                                  Find Work
                                </a>
                                <a
                                  href="page-freelancer-v1.html"
                                  className="ud-btn btn-dark"
                                >
                                  Find Talent
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="slide slide-one"
                        style={{
                          backgroundImage:
                            "url('./assets/images/home/home-1.jpg')",
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-xl-7 mx-auto text-center">
                              <h3 className="banner-title">
                                With talented freelancers{" "}
                                <br className="d-none d-lg-block" />
                                ando more work.
                              </h3>
                              <p className="banner-text text-white ff-heading mb30">
                                Millions of people use freeio.com to turn their
                                ideas into reality.
                              </p>
                              <div className="d-sm-flex justify-content-center banner-btn">
                                <a
                                  href="page-project-v1.html"
                                  className="ud-btn btn-white me-0 me-sm-3"
                                >
                                  Find Work
                                </a>
                                <a
                                  href="page-freelancer-v1.html"
                                  className="ud-btn btn-dark"
                                >
                                  Find Talent
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.main-banner-wrapper -->  */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Talent by category --> */}
          <section className="pb40-md pb90">
            <div className="container">
              <div
                className="row align-items-center wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="col-lg-9">
                  <div className="main-title2">
                    <h2 className="title">Popular Category</h2>
                    <p className="paragraph">Industry wise jobs</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="text-start text-lg-end mb-4 mb-lg-0">
                    <a href="/all-categories" className="ud-btn2">
                      All Categories<i className="fal fa-arrow-right-long"></i>
                    </a>
                  </div>
                </div>
              </div>
              <Spin spinning={this.state.industry_loader}>
                <div className="row d-lg-flex wow fadeInUp">
                  {this.state.industry_list &&
                    this.state.industry_list.map((item, index) => {
                      if (index < 12) {
                        return (
                          <div
                            className="col-sm-6 col-lg-4 col-xl-3"
                            key={index}
                          >
                            <a
                              href={`/job-by-categories?id=${item.industry_id}`}
                            >
                              <div className="iconbox-style1 bdr1 d-flex align-items-start mb30">
                                <div className="icon flex-shrink-0">
                                  <span className="flaticon-web-design-1"></span>
                                </div>
                                <div className="details ml40">
                                  <p className="mb-0 text">{item.count} Jobs</p>
                                  <h5 className="title">{item.label}</h5>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      }
                    })}
                </div>
              </Spin>
            </div>
          </section>

          {/* <!-- Popular Services --> */}
          <section className="pb40-md pb70 mt0-lg" style={{ paddingTop: 40 }}>
            <div className="container">
              <div className="row align-items-center wow fadeInUp">
                <div className="col-lg-9">
                  <div className="main-title mb30-lg">
                    <h2 className="title">Top Companies</h2>
                    <p className="paragraph">Most and all-time top companies</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="text-start text-lg-end mb-4 mb-lg-2">
                    <a className="ud-btn2" href="/companies">
                      Other Companies<i className="fal fa-arrow-right-long"></i>
                    </a>
                  </div>
                </div>
              </div>
              <Spin spinning={this.state.companyLoader}>
                <div className="row">
                  {this.state.companies.map((item, index) => {
                    if (index < 16) {
                      return (
                        <div className="col-sm-6 col-xl-3" key={index}>
                          <div className="listing-style1 style5">
                            <div className="list-thumb">
                              <img
                                className="w-100"
                                src={item.image_url}
                                alt=""
                                style={{
                                  maxWidth: 151,
                                  margin: "10px auto",
                                  display: "flex",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </Spin>
            </div>
          </section>

          <Modal
            visible={this.state.viewModalStatus}
            title={false}
            okText="Ok"
            cancelText="Close"
            onOk={() => this.setState({ viewModalStatus: false })}
            onCancel={() => this.setState({ viewModalStatus: false })}
            width={700}
          >
            <div className="mb-3 mt-4">
              <h6 class="text-center text-danger pb-2">
                IMPORTANT SECURITY NOTICE
              </h6>
              <div class="content-of-div">
                <p style={{ color: "#000", fontSize: 14, fontWeight: "400" }}>
                  bluechipjobs.co.in is a company that provides Recruitment,
                  Outsourcing, BPO, and other services Pan India.
                  bluechipjobs.co.in is committed to ensuring the safety of its
                  users. To provide the safest possible environment, we
                  recommend that our users take a few simple security
                  precautions for a safe user experience.
                  <br />
                  <br />
                  There may be instances that users may receive fraudulent
                  emails that appear as if they came from bluechipjobs asking
                  for their details.
                  <br />
                  <br />
                  To avoid fraudulently you should know about the company’s
                  policy for Job seekers, please note the following safety tips:
                  <br />
                  <br />
                  1. For Job seekers the company sends the email only from the
                  following email IDs i.e. bluechipjobs1@gmail.com ,
                  bluechipjobs2@gmail.com , bluechipjobs3@gmail.com . No other
                  email ID is used for the Job seekers. <br /> 2. The company
                  does not charge any registration or subscription fees for
                  bluechipjobs.co.in <br />
                  3. The company does not charge any fees after getting the job
                  through bluechipjobs.co.in <br />
                  4. For job seekers the service is completely free. The company
                  never sends any link for payment.
                  <br /> 5. The original website for users is
                  https://www.bluechipjobs.co.in where you can put the required
                  information &amp; details. to submit the resume.
                  <br /> 6. The company has one associate company which is
                  https:// www.bluechipcares.com which is a platform for
                  Freelancers in 300+ service categories. It connects the
                  freelancers with the customers and works in a subscription
                  model for the freelancers. More details can be visited on the
                  website.
                  <br />
                  <br />
                  <span style={{ color: "red" }}>
                    Please note www.bluechipjobs.co.in do not guarantee/commit
                    any job directly or indirectly. Be aware of fake persons
                    /fake websites/fake companies who are using the name of
                    bluechipjobs.co.in and do not pay any amount to them.
                    <br />
                    <strong>
                      Bluechip Jobs Private Limited will not be responsible
                      directly or indirectly for any such payment made by you/
                      any use to an unknown and unauthorized party/Third party
                      directly or indirectly.
                    </strong>
                  </span>
                  <hr style={{ background: "#000", opacity: 1 }} />
                  <span style={{ fontSize: 16 }}>
                    ब्लूचिप जॉब्स सेवा प्रदाता कंपनी है । यह कंपनी पुरे देश में
                    रिक्रूटमेंट , आउटसोर्सिंग , BPO व अन्य सेवाएं देती है ।
                    bluechipjobs.co.in अपने उपयोगकर्ताओं की सुरक्षा सुनिश्चित
                    करने के लिए प्रतिबद्ध है। सबसे सुरक्षित संभव वातावरण प्रदान
                    करने के लिए, हम अनुशंसा करते हैं कि हमारे उपयोगकर्ता
                    सुरक्षित उपयोगकर्ता अनुभव के लिए कुछ सरल सुरक्षा सावधानी
                    बरतें।
                    <br />
                    <br />
                    ऐसे उदाहरण हो सकते हैं कि उपयोगकर्ताओं को भ्रामक ईमेल
                    प्राप्त हो सकते हैं जो प्रतीत होते हैं जैसे कि वे
                    bluechipjobs.co.in से आए हों
                    <br />
                    <br />
                    Bluechipjobs.co.in सभी नौकरी ढूंढने वाले अपने उपयोगकर्ताओं
                    से धोखा से बचने के लिए कहना चाहती है, कृपया निम्नलिखित
                    सुरक्षा सुझावों का पालन करें:
                    <br />
                    1. ब्लूचिप जॉब्स अपने वेबसाइट bluechipjobs.co.in से सभी
                    नौकरी ढूंढने वाले उपयोगकर्ताओं को दिए गए इ मेल आई डी से हे
                    संपर्क करती है । इन तीन ईमेल आई डी - .
                    <a href="bluechipjobs1@gmail.com" target="_blank">
                      bluechipjobs1@gmail.com
                    </a>{" "}
                    ,
                    <a href="bluechipjobs2@gmail.com" target="_blank">
                      bluechipjobs2@gmail.com
                    </a>{" "}
                    ,{" "}
                    <a href="bluechipjobs3@gmail.com" target="_blank">
                      bluechipjobs3@gmail.com
                    </a>{" "}
                    . के अलावा कोई और ईमेल आई डी से नौकरी ढूंढने वाले
                    उपयोगकर्ताओं को मेल नहीं भेजा जाता है ।<br />
                    2. ब्लूचिप जॉब्स अपने वेबसाइट{" "}
                    <a href="bluechipjobs.co.in" target="_blank">
                      bluechipjobs.co.in
                    </a>{" "}
                    के माध्यम से जॉब हेतु किसी भी प्रकार का रजिस्ट्रेशन या
                    सब्सक्रिप्शन फीस नहीं लेती है ।<br />
                    3. ब्लूचिप जॉब्स अपने वेबसाइट bluechipjobs.co.in के माध्यम
                    से जॉब लगने के पश्चात भी कोई फीस नहीं चार्ज करती है । यह
                    सेवा नौकरी खोजने वाले सभी उपयोगकर्ताओं के लिए बिलकुल मुफ्त
                    सेवा है ।<br />
                    4. कंपनी पेमेंट हेतु कभी कोई लिंक नहीं भेजती है ।<br />
                    5. हमारे सभी उपयोगकर्ता जॉब हेतु मांगी गई जानकारी हमारे मूल
                    वेबसाइट
                    <a href="https://www.bluechipjobs.co.in" target="_blank">
                      https://www.bluechipjobs.co.in
                    </a>{" "}
                    पर दे सकते है ।<br />
                    कंपनी कि एक सहयोगी कंपनी{" "}
                    <a href="https:// www.bluechipcares.com" target="_blank">
                      https:// www.bluechipcares.com
                    </a>{" "}
                    भी है । bluechipcares एक फ्रीलांसिंग प्लेटफार्म है जो पुरे
                    देश में 300 से भी ज्यादा सर्विस श्रेणी में सर्विस देती है ।
                    यह प्लेटफार्म फ्रीलांसर्स और कस्टमर्स को जोड़ती है ।
                    प्लेटफार्म फ्रीलांसर्स के लिए सब्सक्रिप्शन मॉडल पर कार्य
                    करती है ।<br />
                    <br />
                    <span style={{ color: "red" }}>
                      कृपया ध्यान दें, www.bluechipjobs.co.in प्रत्यक्ष या
                      अप्रत्यक्ष रूप से किसी भी नौकरी की गारंटी/प्रतिबद्धता नहीं
                      करता है। फर्जी व्यक्तियों/फर्जी वेबसाइटों/फर्जी कंपनियों
                      से सावधान रहें जो Bluechip Jobs के नाम का उपयोग कर रहे हैं
                      और उन्हें कोई राशि का भुगतान न करें।
                      <br />
                      <br />
                      Bluechip Jobs Private Limited आपके द्वारा किए गए किसी भी
                      भुगतान/किसी अज्ञात और अनधिकृत पार्टी/तृतीय पक्ष को
                      प्रत्यक्ष या अप्रत्यक्ष रूप से किसी भी उपयोग के लिए
                      प्रत्यक्ष या अप्रत्यक्ष रूप से जिम्मेदार नहीं होगा।
                    </span>
                  </span>
                </p>
                {/* <p style={{ color: "red", fontSize: 14, fontWeight: "500" }}>
                  Please note, www.bluechipjobs.co.in do not guarantee/commit
                  any job/assignments directly or indirectly. Be aware of fake
                  persons /fake websites/fake companies who are using the name
                  of bluechip cares and do not pay any amount to them. We
                  recommend all our users for any payments use the original
                  website www.bluechipjobs.co.in <br />
                  <br />
                  Bluechip Cares Private Limited will not be responsible
                  directly or indirectly for any such payment made by you/ any
                  use to an unknown and unauthorized party/Third party directly
                  or indirectly.
                </p> */}
                {/* 
                <br />
                <p style={{ color: "red", fontSize: 14, fontWeight: "500" }}>
                  bluechipjobs.co.in अपने उपयोगकर्ताओं की सुरक्षा सुनिश्चित करने
                  के लिए प्रतिबद्ध है। सबसे सुरक्षित संभव वातावरण प्रदान करने के
                  लिए, हम अनुशंसा करते हैं कि हमारे उपयोगकर्ता सुरक्षित
                  उपयोगकर्ता अनुभव के लिए कुछ सरल सुरक्षा सावधानी बरतें।
                  <br />
                  <br />
                  ऐसे उदाहरण हो सकते हैं कि उपयोगकर्ताओं को भ्रामक ईमेल प्राप्त
                  हो सकते हैं जो प्रतीत होते हैं जैसे कि वे bluechipcares से
                  उनके लॉगिन विवरण मांगने के लिए आए हों
                  <br />
                  <br />
                  अपने bluechipjobs.co.in खाते के दुरुपयोग से बचने के लिए, कृपया
                  निम्नलिखित सुरक्षा सुझावों का पालन करें: <br />
                  1. ईमेल का जवाब न दें। <br />
                  2. अपना कोई भी व्यक्तिगत/आईडी विवरण न भेजें
                  <br />
                  3. कृपया ध्यान दें कि हम कभी भी ईमेल में आपका पासवर्ड नहीं
                  पूछेंगे।
                  <br />
                  4. मेल में लिंक द्वारा प्रदान किए गए पृष्ठों पर अपना कोई भी
                  विवरण दर्ज न करें
                  <br />
                  5. उपयोगकर्ताओं के लिए मूल वेबसाइट
                  https://www.bluechipjobs.co.in है जहां आप आवश्यक जानकारी और
                  विवरण डाल सकते हैं ।<br />
                  6. कंपनी कभी भी पेमेंट के लिए कोई लिंक नहीं भेजती।
                  उपयोगकर्ताओं किसी भी भुगतान हेतु www.bluechipjobs.co.in के
                  माध्यम से पेमेंट गेटवे का इस्तेमाल कर कोई भी भुगतान कर सकते है{" "}
                  <br />
                  7. यदि आपने मेल की स्कैम सामग्री की जानकारी के बिना ऐसी कोई
                  जानकारी भेजी है, तो हम आपसे आग्रह करते है तुरंत अपना पासवर्ड
                  बदलें। <br />
                  <br />
                  अन्य सूचना <br />
                  8. यह प्लेटफॉर्म गिग वर्कर्स (सेवा प्रदाता / सर्विस
                  प्रोवाइडर्स) के लिए है, जो फ्रीलांसिंग कार्य (असाइनमेंट) लेकर
                  प्लेटफार्म के जरिए कमाते हैं | गिग वर्कर्स (सेवा प्रदाता /
                  सर्विस प्रोवाइडर्स) किसी भी मामले में कंपनी के कर्मचारी नहीं
                  हैं। <br />
                  9. ग्राहकों से अनुरोध है कि वे गिग वर्कर्स (सेवा प्रदाता /
                  सर्विस प्रोवाइडर्स) से सीधे व्यवहार न करें और सेवाएं
                  प्लेटफॉर्म के माध्यम से ही लें | कंपनी किसी भी सेवाएं / सौदे
                  या लेन-देन के लिए ज़िम्मेदार नहीं होगी जो प्लेटफॉर्म के माध्यम
                  से पूरा नहीं हुआ है Iप्लेटफार्म के माध्यम से ली गई सेवाएं आपके
                  डैशबोर्ड पर ऑनलाइन रिकॉर्ड के माध्यम से उपलब्ध होता है ।{" "}
                  <br />
                  <br />
                </p>
                <p style={{ color: "red", fontSize: 14, fontWeight: "500" }}>
                  कृपया ध्यान दें, www.bluechipjobs.co.in प्रत्यक्ष या
                  अप्रत्यक्ष रूप से किसी भी नौकरी/असाइनमेंट / कार्य की
                  गारंटी/प्रतिबद्धता नहीं करता है। फर्जी व्यक्तियों/फर्जी
                  वेबसाइटों/फर्जी कंपनियों से सावधान रहें जो Bluechip Cares के
                  नाम का उपयोग कर रहे हैं और उन्हें कोई राशि का भुगतान न करें।
                  हम अपने सभी उपयोगकर्ताओं को किसी भी भुगतान के लिए मूल वेबसाइट
                  www.bluechipjobs.co.in का उपयोग करने की सलाह देते हैं <br />
                  <br />
                  Bluechip Cares Private Limited आपके द्वारा किए गए किसी भी
                  भुगतान/किसी अज्ञात और अनधिकृत पार्टी/तृतीय पक्ष को प्रत्यक्ष
                  या अप्रत्यक्ष रूप से किसी भी उपयोग के लिए प्रत्यक्ष या
                  अप्रत्यक्ष रूप से जिम्मेदार नहीं होगा।
                </p> */}
              </div>
            </div>
          </Modal>

          <Footer />
        </div>
      </div>
    );
  }
}
